import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// Components
import Btn from '@/components/Button/Btn.vue';
import LodgeCard from '@/views/Lodges/Components/LodgeCard/LodgeCard.vue';
import PackageCard from '@/views/Packages/Components/PackageCard/PackageCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
// Services
import WishlistService from '@/services/wishlists';
let WishlistPage = class WishlistPage extends Vue {
    constructor() {
        super(...arguments);
        this.lodges = [];
        this.packages = [];
        this.loading = true;
        this.currentLodgeIndex = 0;
        this.currentLodge = null;
        this.coverImageDesc = '';
        this.onlyNonMalaria = false;
        this.currencyOptions = [];
        this.currency = null;
        this.currencies = [];
        this.cardIcon = ['fal', 'trash-alt'];
    }
    async currencyUpdated(newVal) {
        if (this.currency != null) {
            let selected = this.currencies.filter((c) => {
                return c.code === this.currency;
            });
            await this.$store.dispatch('app/setCurrency', selected[0]);
        }
        else {
            await this.$store.dispatch('app/setCurrency', null);
        }
    }
    created() {
    }
    async mounted() {
        await this.$store.commit('lodges/SET_EMPTY');
        await this.$store.commit('stories/SET_EMPTY');
        await this.$store.commit('projects/SET_EMPTY');
        await this.$store.commit('discussions/SET_EMPTY');
        await this.resetLodgeValues();
        await this.getLodges();
        await this.getPackages();
        this.loading = false;
        // Setup the currency selector
        await this.$store.dispatch('app/getCurrencies');
        this.currencies = this.$store.getters['app/currencies'];
        this.currencyOptions = this.currencies?.map((currency) => {
            return {
                name: `${currency.code} - ${currency.name}`,
                value: currency.code,
                selected: false,
            };
        });
        if (this.$store.getters['app/selectedCurrency'] !== null) {
            this.currency = this.$store.getters['app/selectedCurrency'].code;
        }
    }
    async getLodges() {
        try {
            // Get lodges for this wishlist
            const response = await WishlistService.getWishlistLodges(this.wishlist.slug);
            this.lodges = response.data.lodges;
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
        }
    }
    async getPackages() {
        try {
            // Get packages for this wishlist
            const response = await WishlistService.getWishlistPackages(this.wishlist.slug);
            this.packages = response.data.packages;
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
        }
    }
    async beforeDestroy() {
        await this.resetLodgeValues();
    }
    async resetCurrency() {
        this.currency = null;
        await this.$store.dispatch('app/setCurrency', null);
    }
    async resetLodgeValues() {
    }
    async redirectToWishlistsPage() {
        this.$router.push({ path: `/wishlists` });
    }
    async redirectToQuestionsPage() {
        await this.$store.dispatch('wishlists/setActiveWishlist', this.wishlist);
        this.$router.push({ path: `/questions` });
    }
    async refreshLodgesData() {
        await this.getLodges();
    }
    async refreshPackagesData() {
        await this.getPackages();
    }
};
__decorate([
    Getter('activeWishlist', { namespace: 'wishlists' })
], WishlistPage.prototype, "wishlist", void 0);
__decorate([
    Watch('currency')
], WishlistPage.prototype, "currencyUpdated", null);
WishlistPage = __decorate([
    Component({
        components: {
            Btn,
            LodgeCard,
            PackageCard,
            TextInput,
            CoverImage,
            SelectInput,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | wishlist',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic wishlist',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/wishlist` }],
            };
        },
    })
], WishlistPage);
export default WishlistPage;
