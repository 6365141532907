import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import WishlistCard from '@/views/Wishlists/Components/WishlistCard/WishlistCard.vue';
import CreateWishlist from '@/views/Wishlists/Sections/CreateWishlist.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SearchInput from '@/components/FormInputs/SearchInput/SearchInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
// Services
import WishlistService from '@/services/wishlists';
let Wishlists = class Wishlists extends Vue {
    constructor() {
        super(...arguments);
        this.wishlists = [];
        this.searchValue = '';
        this.searchError = [];
        this.loading = true;
        this.paginationLoaded = false;
        this.coverImageDesc = '';
        this.searchTimeout = null;
    }
    async searchUpdated(newVal) {
        // We do this timeout to clear timeout dance
        // so that the search is only triggered 300ms after the user has stopped typing.
        // We trigger loading immediately though to provide feedback
        clearTimeout(this.searchTimeout);
        this.loading = true;
        // Set the search term & rest page
        await this.$store.dispatch('wishlists/setSearch', newVal);
        if (this.$route.query.search !== newVal) {
            await this.$store.dispatch('wishlists/setPage', 1);
        }
        else {
            await this.$store.dispatch('wishlists/setPage', this.$route.query.page);
        }
        let self = this;
        this.searchTimeout = setTimeout(async function () {
            // Call the page load
            await self.$store.dispatch('wishlists/getWishlists');
            self.loading = false;
            self.wishlists = self.$store.getters['wishlists/getWishlists'];
        }, 500);
    }
    created() {
        if (history.state.search && history.state.page) {
            if (this.$route.fullPath === `/wishlists?search=${history.state.search}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({ path: `/wishlists?search=${history.state.search}&page=${history.state.page}` });
            }
        }
    }
    async mounted() {
        await this.resetWishlistsValues();
        if (this.$route.query.search) {
            await this.$store.dispatch('wishlists/setSearch', this.$route.query.search);
            this.searchValue = this.$store.getters['wishlists/getSearchTerm'];
        }
        if (this.$route.query.page) {
            await this.$store.dispatch('wishlists/setPage', this.$route.query.page);
        }
        await this.getAllWishlists();
        this.paginationLoaded = true;
        this.loading = false;
    }
    async getAllWishlists() {
        try {
            const response = await WishlistService.get();
            this.wishlists = response.data.wishlists;
            this.$store.dispatch('pagination/setPagination', response.data.pagination);
            this.$store.dispatch('user/resetSingleResourceCount', 'wishlists');
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
        }
    }
    async beforeDestroy() {
        await this.resetWishlistsValues();
    }
    async resetWishlistsValues() {
        await this.$store.dispatch('wishlists/setSearch', '');
        await this.$store.dispatch('wishlists/setFilter', '');
        await this.$store.dispatch('wishlists/setPage', 1);
    }
    // Loading from child components
    async setLoadingEvent(payload) {
        this.loading = payload.loading;
        this.getAllWishlists();
    }
    async paginate(page) {
        await this.$store.dispatch('wishlists/setPage', page);
        var query = {};
        query['page'] = page.toString();
        if (this.searchValue != '') {
            query['search'] = this.searchValue;
        }
        this.$router.push({
            name: 'wishlists',
            query: query,
        });
    }
};
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], Wishlists.prototype, "pagination", void 0);
__decorate([
    Watch('searchValue')
], Wishlists.prototype, "searchUpdated", null);
Wishlists = __decorate([
    Component({
        components: {
            Btn,
            WishlistCard,
            CreateWishlist,
            TextInput,
            SearchInput,
            CoverImage,
            SelectInput,
            Pagination,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | wishlists',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic wishlists',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/wishlists` }],
            };
        },
    })
], Wishlists);
export default Wishlists;
