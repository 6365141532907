import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
// components
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
let CreateWishlist = class CreateWishlist extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: ''
        };
        this.errors = {
            name: [],
        };
        this.formOpen = false;
        this.disabled = false;
        this.loading = false;
        this.error = '';
    }
    async mounted() { }
    async click() {
        this.formOpen = !this.formOpen;
    }
    async submit() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('name', this.form.name);
        try {
            this.loading = true;
            this.$emit('loading-event', { loading: false });
            this.disabled = true;
            const resp = await this.$store.dispatch('wishlists/create', formData);
            await this.$store.dispatch('wishlists/getWishlists'),
                this.form.name = '';
            this.formOpen = false;
            this.disabled = false;
            this.loading = false;
            this.$emit('loading-event', { loading: false });
            this.$emit('wishlist-created', { loading: false });
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.$emit('loading-event', { loading: false });
            this.$emit('wishlist-created', { loading: false });
            if (e.data.messages) {
                this.errors = e.data.messages;
            }
            if (e.data.restricted_words) {
                this.errors.restricted_words = e.data.restricted_words;
            }
            this.error = e.message;
        }
    }
    async cancel() {
        this.form.name = '';
        this.formOpen = false;
    }
};
__decorate([
    Prop()
], CreateWishlist.prototype, "wishlist", void 0);
CreateWishlist = __decorate([
    Component({
        components: {
            TextInput
        }
    })
], CreateWishlist);
export default CreateWishlist;
