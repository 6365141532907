import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let IndicativeRate = class IndicativeRate extends Vue {
    numberFormat(price) {
        return new Intl.NumberFormat('fr-FR').format(price);
    }
};
__decorate([
    Prop()
], IndicativeRate.prototype, "name", void 0);
__decorate([
    Prop()
], IndicativeRate.prototype, "currencySymbol", void 0);
__decorate([
    Prop()
], IndicativeRate.prototype, "value", void 0);
__decorate([
    Prop()
], IndicativeRate.prototype, "description", void 0);
IndicativeRate = __decorate([
    Component
], IndicativeRate);
export default IndicativeRate;
