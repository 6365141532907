import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
// components
import Btn from '@/components/Button/Btn.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
let WishlistCard = class WishlistCard extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: this.wishlist.name
        };
        this.errors = {
            name: [],
        };
        this.formOpen = false;
        this.disabled = false;
        this.showDeleteModal = false;
        this.loading = false;
        this.error = '';
    }
    async click() {
        await this.$store.dispatch('wishlists/setActiveWishlist', this.wishlist);
        this.$router.push({ path: `/wishlist/${this.wishlist.slug}` });
    }
    async edit() {
        this.formOpen = !this.formOpen;
    }
    async submit() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('_method', 'put');
        formData.append('name', this.form.name);
        try {
            this.loading = true;
            this.$emit('loading-event', { loading: this.loading });
            this.disabled = true;
            const payload = {
                formData: formData,
                slug: this.wishlist.slug
            };
            const resp = await this.$store.dispatch('wishlists/update', payload);
            await this.$store.dispatch('wishlists/getWishlists');
            this.form.name = this.wishlist.name;
            this.formOpen = false;
            this.disabled = false;
            this.loading = false;
            this.$emit('loading-event', { loading: this.loading });
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.$emit('loading-event', { loading: this.loading });
            if (e.data.messages) {
                this.errors = e.data.messages;
            }
            if (e.data.restricted_words) {
                this.errors.restricted_words = e.data.restricted_words;
            }
            this.error = e.message;
        }
    }
    async cancel() {
        this.form.name = this.wishlist.name;
        this.formOpen = false;
    }
    showDeleteConfirmation() {
        this.showDeleteModal = true;
    }
    hideDeleteConfirmation() {
        this.showDeleteModal = false;
    }
    async deleteWishlist() {
        if (this.disabled) {
            return;
        }
        try {
            this.loading = true;
            this.showDeleteModal = false;
            this.$emit('loading-event', { loading: this.loading });
            this.disabled = true;
            await this.$store.dispatch('wishlists/delete', this.wishlist.slug);
            await this.$store.dispatch('wishlists/getWishlists');
            this.disabled = false;
            this.loading = false;
            this.$emit('loading-event', { loading: this.loading });
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.$emit('loading-event', { loading: this.loading });
            this.errors = e;
        }
    }
};
__decorate([
    Prop()
], WishlistCard.prototype, "wishlist", void 0);
WishlistCard = __decorate([
    Component({
        components: {
            Btn,
            TextInput
        }
    })
], WishlistCard);
export default WishlistCard;
