import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
//components
import Card from '@/components/Card/Card.vue';
import WishlistModal from '@/views/Wishlists/Components/WishlistModal.vue';
import IndicativeRate from '@/views/Lodges/Components/IndicativeRate/IndicativeRate.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
import Btn from '@/components/Button/Btn.vue';
let LodgeCard = class LodgeCard extends Vue {
    constructor() {
        super(...arguments);
        this.showWishlistDropdown = false;
        this.isLodgeInWishlists = false;
        this.showWishlistModal = false;
        this.showDeleteModal = false;
        this.disabled = false;
        this.loading = false;
        this.error = '';
        this.form = {
            wishlistable_type: 'lodge',
            wishlistable_id: this.lodge.id,
            action: 'remove',
            wishlist_ids: [],
        };
    }
    get indicativeRates() {
        return this.lodge && this.lodge.rates
            ? this.lodge.rates.filter((rate) => rate.indication_rate === true)
            : [];
    }
    async mounted() {
        if (this.$store.getters["auth/loggedIn"]) {
            await this.$store.dispatch('wishlists/getWishlists');
        }
    }
    async click() {
        await this.$store.dispatch('lodges/setActiveLodge', this.lodge);
        this.$router.push({ path: `/lodge/${this.lodge.slug}` });
    }
    getCoverImage() {
        if (this.lodge && this.lodge.hero_image && this.lodge.hero_image.public_url) {
            return this.lodge.hero_image.public_url;
        }
        else if (this.lodge.images && this.lodge.images.length > 0) {
            return this.lodge.images[0].public_url;
        }
        else {
            return null;
        }
    }
    getCoverImageCopyright() {
        if (this.lodge && this.lodge.hero_image && this.lodge.hero_image.public_url && this.lodge.hero_image_copyright) {
            return this.lodge.hero_image_copyright;
        }
        else {
            return '';
        }
    }
    async pushHistoryState(url) {
        await history.pushState({ search: this.$store.state.lodges.searchTerm, page: this.$store.state.lodges.page }, 'Lodges', this.$route.fullPath);
        this.$router.push({ path: url });
    }
    async setIconAction() {
        if (this.iconAction === 'dropdown') {
            return this.toggleWishlistDropdown();
        }
        if (this.iconAction === 'delete') {
            return this.openDeleteModal();
        }
    }
    async toggleWishlistDropdown() {
        this.showWishlistDropdown = !this.showWishlistDropdown;
        // Check if lodge is already in user wishlists
        this.wishlists.forEach((wishlist) => {
            wishlist.lodges?.forEach((lodge) => {
                if (lodge.id === this.lodge.id) {
                    this.isLodgeInWishlists = true;
                }
            });
        });
    }
    async openWishlistModal() {
        if (!this.$store.getters['auth/loggedIn']) {
            // this.showSignInOverlay = true;
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        this.showWishlistModal = true;
        this.showWishlistDropdown = false;
    }
    async setLoadingEvent(payload) {
        this.showWishlistModal = false;
        this.isLodgeInWishlists = false;
    }
    async openDeleteModal() {
        this.showDeleteModal = true;
    }
    async closeDeleteModal() {
        this.showDeleteModal = false;
    }
    async removeLodge() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('wishlistable_type', this.form.wishlistable_type);
        formData.append('wishlistable_id', this.form.wishlistable_id);
        // append action
        formData.append('action', this.form.action);
        // append the only wishlist_id
        formData.append('wishlist_ids[]', this.wishlist.id);
        try {
            this.loading = true;
            this.disabled = true;
            const resp = await this.$store.dispatch('wishlists/addOrRemoveItem', formData);
            this.disabled = false;
            this.showDeleteModal = false;
            this.$emit('remove-lodge');
            this.loading = false;
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.error = e.message;
            this.$emit('loading-event', { loading: this.loading });
        }
    }
};
__decorate([
    Getter('getWishlists', { namespace: 'wishlists' })
], LodgeCard.prototype, "wishlists", void 0);
__decorate([
    Getter('activeWishlist', { namespace: 'wishlists' })
], LodgeCard.prototype, "wishlist", void 0);
__decorate([
    Prop()
], LodgeCard.prototype, "lodge", void 0);
__decorate([
    Prop({ default: false })
], LodgeCard.prototype, "btn", void 0);
__decorate([
    Prop({ default: '' })
], LodgeCard.prototype, "icon", void 0);
__decorate([
    Prop({ default: '' })
], LodgeCard.prototype, "iconAction", void 0);
LodgeCard = __decorate([
    Component({
        components: { Card, IndicativeRate, MarkdownFormatter, Btn, WishlistModal },
    })
], LodgeCard);
export default LodgeCard;
